import swal from 'sweetalert'
import moment from 'moment'
moment.locale('es')

const addZeroToLeft = function (value) {
  return value > 9 ? value : '0' + value
}

const alertError = function (error, description = null, status = null) {
  const message = error.message || error.error
  if (message) {
    swal('Error', message, 'error')
  } else if (error !== null && typeof error === 'object' && Object.keys(error).length > 0) {
    let list = document.createElement('ul')
    let items = ''
    Object.keys(error).forEach(function (key) {
      items += `<li>${error[key][0]}</li>`
    })
    list.innerHTML = items

    swal({
      title: 'Error',
      content: list,
      icon: 'error'
    })
  } else {
    console.error(description || 'Error from alert: ', error)
  }

  if (status === 'Unauthorized') {
    setTimeout(() => {
      location.reload()
    }, 5000)
  }
}

const convertToCurrency = function (val) {
  if (!val) return '$ 0'
  let isNegative = false
  let str = val.toString().trim()
  let base = str
  if (str.charAt(0) === '-') {
    base = str.substr(1, str.length).trim()
    isNegative = true
  }
  let splitedVal = base.split('.')
  let reversedVal = splitedVal[0].split('').reverse()
  let formattedVal = ''
  reversedVal.forEach((c, i) => {
    formattedVal += c + ((i + 1) % 3 === 0 && reversedVal[i + 1] !== undefined ? '.' : '')
  })
  return `$ ${isNegative ? '-' : ''}` + (splitedVal[1] !== undefined && parseInt(splitedVal[1]) > 0 ? `${formattedVal.split('').reverse().join('')},${splitedVal[1].slice(0, 2)}` : formattedVal.split('').reverse().join(''))
}

const convertToNumber = function (val) {
  if (!val) return 0
  return typeof val === 'string' ? parseFloat(val.replace(/[\\$.]/g, '').replace(',', '.')) : typeof val === 'number' ? val : 0
}

const createToast = function (title, message, type = 'info', delay = 5000) {
  const toastContainer = document.getElementById('toast-list')
  if (toastContainer) {
    let icon = '<span class="material-icons text-success">thumb_up_alt</span>'
    switch (type) {
      case 'info':
        icon = '<span class="material-icons text-info">info</span>'
        break
      case 'warning':
        icon = '<span class="material-icons text-warning">bug_report</span>'
        break
      case 'error':
        icon = '<span class="material-icons text-danger">bug_report</span>'
        break
    }

    const id = Math.floor(Math.random() * 10000)

    toastContainer.innerHTML +=
      `<div id="toast-${id}" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="${delay}">
        <div class="toast-header">
          ${icon}
          <strong class="ml-1 mr-auto">${title}</strong>
          <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          ${message}
        </div>
      </div>`

    jQuery(`#toast-${id}`).toast('show')
    setTimeout(() => {
      jQuery(`#toast-${id}`).remove()
    }, delay)
  }
}

const cutDates = function (time) {
  let itime = time
  if (typeof itime === 'string') {
    itime = parseInt(itime)
  }

  const date = new Date(itime * 1000)
  const ct = new Date()

  if (ct.getFullYear() !== date.getFullYear() || ct.getMonth() !== date.getMonth() || ct.getDate() !== date.getDate()) {
    return dateTimeToString(date)
  }

  return timeToString(date)
}

const diffHours = function (dt2, dt1) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= (60 * 60)
  return Math.abs(Math.round(diff))
}

const dateTimeToString = function (time) {
  let d = typeof time === 'string' ? parseInt(time) : time
  if (typeof d === 'number') {
    d = new Date(d * 1000)
  }

  const month = addZeroToLeft(d.getMonth() + 1)
  const day = addZeroToLeft(d.getDate())

  return `${day}-${month}-${d.getFullYear()} ${timeToString(d)}`
}

const formatDatetime = function (time) {
  let itime = time
  if (typeof itime === 'string') {
    itime = parseInt(itime)
  }
  const mDate = moment(itime * 1000)
  return `${capitalize(mDate.format('dddd D'))} de ${capitalize(mDate.format('MMMM YYYY'))} a las ${mDate.format('h:mm A')}`
}

const timeToString = function (time) {
  let d = time
  if (typeof time === 'number') {
    d = new Date(time * 1000)
  }

  const hour = addZeroToLeft(d.getHours() > 12 ? d.getHours() - 12 : d.getHours() === 0 ? 12 : d.getHours())
  const minute = addZeroToLeft(d.getMinutes())
  const acronym = d.getHours() >= 12 ? 'pm' : 'am'

  return `${hour}:${minute} ${acronym}`
}

export { addZeroToLeft, alertError, convertToCurrency, convertToNumber, createToast, cutDates, dateTimeToString, diffHours, formatDatetime, timeToString }
