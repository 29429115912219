import swal from 'sweetalert'
import Rails from '@rails/ujs'

const getButtonTargetted = function (target) {
  let tagName = target.tagName.toLocaleLowerCase()
  return tagName === 'button' || tagName === 'a' ? target : tagName === 'form' ? target.querySelector('button[type="submit"], input[type="submit"]') : target.parentElement
}

const animateButton = function (button, state = 'success', originalClass = 'btn-primary') {
  const originalContent = button.innerHTML

  switch (state) {
    case 'success':
      button.classList.remove('btn-danger', originalClass)
      button.classList.add('btn-success')
      button.innerHTML = '<span class="material-icons">done</span>'
      break
    case 'error':
      button.classList.remove('btn-success', originalClass)
      button.classList.add('btn-danger')
      button.innerHTML = '<span class="material-icons">close</span>'
      break
  }

  setTimeout(function() {
    button.classList.remove('btn-success', 'btn-danger')
    button.classList.add(originalClass)
    button.innerHTML = originalContent
  }, 5000)
}

const deleteRecord = function (event) {
  event.preventDefault()
  Rails.stopEverything(event)
  let element = getButtonTargetted(event.target)
  const title = element.dataset.title
  const message = element.dataset.message

  swal({
    title: title || '¿Está usted seguro?',
    text: message,
    icon: 'warning',
    dangerMode: true,
    buttons: {
      cancel: element.dataset.cancelButton || 'Cancelar',
      confirm: element.dataset.confirmButton || 'Eliminar'
    }
  })
    .then((willDelete) => {
      if (willDelete) {
        Rails.ajax({
          url: element.href,
          type: 'delete',
          dataType: 'html',
          error: function (error) {
            console.error('Error trying to delete a record: ', error)
          }
        })
      }
    })

  return false
}

const disableButton = function (button) {
  let $b = jQuery(button)
  $b.children().hide()
  $b.append('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Cargando...</span>')
  $b.attr('disabled', 'disabled')
}

const enableButton = function (button) {
  let $b = jQuery(button)
  $b.find('span.spinner-border, span.sr-only').remove()
  $b.children().show()
  $b.removeAttr('disabled')
}

const getButtonWrapper = function (target) {
  let tagName = target.tagName.toLocaleLowerCase()
  return tagName !== 'i' && tagName !== 'span' ? target : target.parentElement
}

const putOrderOnWay = function (event) {
  event.preventDefault()
  event.stopPropagation()
  const button = getButtonWrapper(event.target)
  disableButton(button)

  Rails.ajax({
    url: button.href,
    type: 'PUT',
    dataType: button.dataset.type || 'html',
    success: () => {
      if (button.dataset.redirect) {
        location.href = button.dataset.redirect
      }
    },
    error: (error) => {
      if (error.message) {
        swal('Error', error.message, 'error')
      }
      enableButton(button)
    }
  })
}

const toggleProductStatus = function (event) {
  event.preventDefault()
  let element = event.target
  if (element.tagName !== "DIV") {
    element = element.closest('div')
  }
  disableButton(element)

  Rails.ajax({
    url: element.dataset.url,
    type: 'put',
    dataType: 'html',
    success: function (data) {
      if (data) {
        element.innerHTML = data.activeElement.innerHTML
      }
      enableButton(element)
    },
    error: function (error) {
      console.error("Error trying to change an item's status: ", error)
      enableButton(element)
    }
  })
}

export { animateButton, deleteRecord, disableButton, enableButton, getButtonTargetted, getButtonWrapper, putOrderOnWay, toggleProductStatus }
