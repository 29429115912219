import Vue from 'vue/dist/vue.esm'
import Rails from '@rails/ujs'
import swal from 'sweetalert'
import { alertError } from '../functions.js'
import { animateButton, disableButton, enableButton, getButtonTargetted } from '../buttons.js'
import UploadButton from '../common/upload_button.js'

const $el = document.getElementById('adoption-documents-form')
if ($el != null) {
  const DOCUMENTS_URL = $el.dataset.url

  new Vue({
    el: $el,
    data: {
      inactiveUpload: true,
      documents: [],
      isLoading: false
    },
    mounted() {
      this.loadDocuments()
      if (this.$refs.uploadField) {
        new UploadButton(this.$refs.uploadField)
      }
    },
    methods: {
      deleteDocument(event, documentId) {
        const deleteButton = getButtonTargetted(event.target)
        disableButton(deleteButton)

        swal({
          title: deleteButton.dataset.question || '¿Esta usted segur@?',
          icon: 'warning',
          dangerMode: true,
          buttons: {
            cancel: deleteButton.dataset.cancel || 'Cancelar',
            confirm: deleteButton.dataset.confirm || deleteButton.title
          }
        })
          .then((willDelete) => {
            const self = this
            if (willDelete) {
              Rails.ajax({
                url: `${DOCUMENTS_URL}/${documentId}`,
                type: 'DELETE',
                dataType: 'json',
                success: (response) => {
                  const index = self.documents.findIndex((document) => document.id === documentId)
                  if (index >= 0) {
                    self.documents.splice(index, 1)
                  }

                  enableButton(deleteButton)
                  animateButton(deleteButton, 'success', 'btn-danger')
                },
                error: (error) => {
                  enableButton(deleteButton)
                  animateButton(deleteButton, 'error', 'btn-danger')
                  alertError(error)
                }
              })
            } else {
              enableButton(deleteButton)
            }
          })
      },
      loadDocuments() {
        this.isLoading = true

        Rails.ajax({
          url: DOCUMENTS_URL,
          type: 'GET',
          dataType: 'json',
          success: (documents) => {
            this.documents = documents

            this.isLoading = false
          },
          error: (error) => {
            this.isLoading = false
            alertError(error)
          }
        })
      },
      uploadDocument(event) {
        event.preventDefault()
        event.stopPropagation()

        const form = event.target
        const submitButton = getButtonTargetted(form)
        disableButton(submitButton)
        const self = this

        Rails.ajax({
          url: form.action,
          type: 'POST',
          dataType: 'json',
          data: new FormData(form),
          success: (document) => {
            self.documents.push(document)
            form.reset()
            if (self.$refs.attachmentOutput) {
              self.$refs.attachmentOutput.innerHTML = ''
            }

            enableButton(submitButton)
            animateButton(submitButton, 'success')
          },
          error: (error) => {
            enableButton(submitButton)
            animateButton(submitButton, 'error')
            alertError(error)
          }
        })
      },
      toggleSubmit(event) {
        if (event.target.value) {
          this.inactiveUpload = false
        } else {
          this.inactiveUpload = true
        }
      }
    }
  })
}

