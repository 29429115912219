/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
//= require dataTables/jquery.dataTables

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
window.jQuery = require('jquery')
require('popper.js')
require('bootstrap/dist/js/bootstrap.min')
require('datatables.net-bs4')
require('./vue/adoption_attachments')

window.jQuery(document).ready(function () {
  window.jQuery('#adoptFirends-table').DataTable({
      "language": {
          "sProcessing": "Procesando...",
          "sLengthMenu": "Mostrar _MENU_ registros",
          "sZeroRecords": "No se encontraron resultados",
          "sEmptyTable": "Ningún dato disponible en esta tabla",
          "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
          "sInfoPostFix": "",
          "sSearch": "Buscar:",
          "sUrl": "",
          "sInfoThousands": ",",
          "sLoadingRecords": "Cargando...",
          "oPaginate": {
              "sFirst": "Primero",
              "sLast": "Último",
              "sNext": "Siguiente",
              "sPrevious": "Anterior"
          },
          "oAria": {
              "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sSortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "buttons": {
              "copy": "Copiar",
              "colvis": "Visibilidad"
          }
      }
  });

  try {
    let pathname = window.location.pathname;
    window.jQuery('.nav-item').removeClass('bg-yellow')
    window.jQuery('#' + pathname.split('/')[2]).addClass('bg-yellow')
  } catch (error) {
    console.error('Error calculating pathname...', error)
  }

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  let forms = document.getElementsByClassName('needs-validation')

  // Loop over them and prevent submission
  Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
          if (form.checkValidity() === false) {
              event.preventDefault()
              event.stopPropagation()
          }
          form.classList.add('was-validated')
      }, false)
  })
});


window.printRejectedFor = (status) => {
  if (status.value == 'Rejected') {
      document.getElementById('rejected_for_div').style.display = 'block'
  } else {
      document.getElementById('rejected_for_div').style.display = 'none'
  }
}

window.validateEmty = (idInput) => {
  let ckEditorInput = 0
  switch (idInput) {
      case 'adoption_canceled_for':
        ckEditorInput = CKEDITOR.instances.adoption_canceled_for.document.getBody().getText().length
        break;
      case 'pet_description':
          ckEditorInput = CKEDITOR.instances.pet_description.document.getBody().getText().length
          break;
      case 'adoption_rejected_for':
          ckEditorInput = CKEDITOR.instances.adoption_rejected_for.document.getBody().getText().length
          break;
      case 'adoption_observation_description':
          ckEditorInput = CKEDITOR.instances.adoption_observation_description.document.getBody().getText().length
          break;
  }

  if (ckEditorInput > 1){
      document.getElementById("btn-save").type = 'submit'
      document.getElementById('canceled_checked').style.display = 'none'
  } else {
      document.getElementById("btn-save").type = 'button'
      document.getElementById('canceled_checked').style.display = 'block'
  }
}

window.loadCity = (department, name = 'admin[city_id]', id='admin_city_id') => {
  console.log('department', department.value)
  printLocalities(department.value)
  window.jQuery.ajax({
      type: "GET",
      url: "/cities",
      data: {department: department.value},
      success: function (a) {
          let html = `<select name="${name}" id="${id}" class="custom-select">`
          a.cities.forEach(city => {
              html += `<option value="${city.id}">${city.name}</option>`
          });
          html += '</select>'
          document.getElementById('cities').innerHTML = html
      }
    });
}

window.printLocalities = (department) => {
  if(department == 3){
      document.getElementById('localities').style.display = 'block'
  }else{
      document.getElementById('localities').style.display = 'none'
  }
}

window.validatePassword = (is = 'admin') => {
  let pass = ''
  let confir = ''
  if (is !== 'admin'){
      pass = document.getElementById('user_password').value
      confir = document.getElementById('user_password_confirmation').value
  }else{
      pass = document.getElementById('admin_password').value
      confir = document.getElementById('admin_password_confirmation').value
  }
  
  let disabled = true
  let message = ''
  if (pass != confir){
      message = 'Las contraseñas deben ser iguales'
  }else if (pass.length < 6){
      message = 'La contraseña debe tener mas de 6 caracteres'
  }else{
      disabled = false
  }
  document.getElementById('msg-pass').innerHTML = message
  //document.getElementById('send-form').disabled = disabled
  
}

window.hasPet = (hasPet) => {
  if(hasPet.value == 'false'){
      document.getElementById('user_type_pet').value = 'N/A'
  }else{
      document.getElementById('user_type_pet').value = ''
  }
}
